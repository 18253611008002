/* text */
.primary-text {
  color: #ffffff;
  font-family: var(--font-family-inter);
  letter-spacing: 0.17em;
}

.header-receive {
  display: flex;
}

.header-receive-text {
  flex-grow: 1;
  flex-shrink: 1;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-left: 25px;
}

/* close */
.close-container {
  padding-top: 10px;
}

.close-text {
  color: #ffffff;
  cursor: pointer;
  padding: 15px;
}

/* Modal */
ion-modal {
  --width: 440px;
  --height: 530px;
  --border-radius: 16px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.header {
  display: flex;
  justify-content: center;
  font-family: var(--font-family-inter);
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0.17em;
}

.select-token {
  background-color: #1c1d1b;
  max-width: 200px;
  color: #ffffff;
  --placeholder-opacity: 1;
}

.col-copy-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-copy-link {
  color: #3661f8;
}

.copy-link {
  color: #3661f8;
  font-size: 14px;
  letter-spacing: 0.17em;
  padding-left: 10px;
}

.copy-text {
  font-size: 12px;
  padding-top: 2px;
}

.container-qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.qr-code {
  width: 65%;
}

.container-adress {
  background: #070707;
  border-radius: 13px;
  height: 45px;
  margin: auto;
}

.disclaimer {
  display: flex;
  align-items: center;
  font-size: 12px;
  background: #1f201d;
  border-radius: 13px;
  color: #B1B1B1;
  padding: 8px 0px 8px 0px;
  margin: auto;
}

.icon-danger {
  width: 15px
}

.button-receive {
  margin-left: 8px;
}