
.container-card-login {
    --background: rgba(123, 123, 123, 0.09);
    --color: #ffffff;
    border-radius: 10px;
    border: 1px solid #000000;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: auto;
  }

  
.button-login {
    --background: #0D788F;
    text-transform: none;
    color: #FFFFFF;
    font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    height: 33px;
    width: 388px;
  }

  .button-twitter {
    background: #0D788F !important;
    text-transform: none !important;
    color: #FFFFFF !important;
    font-family: var(--font-family-inter) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    height: 35px !important;
  }