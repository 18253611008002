.container-content-home {
  margin: 10px 0 0 0;
  background-color: #131411;
}


ion-col {
    /*
    background-color: #135d54;
    border: solid 1px #fff;
    color: #fff;
    */
    text-align: center;
  }

.search {
    display: flex;
    justify-content: center;
}

.home-h1 {
  font-family: var(--font-family-poppins);
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
}

.home-content {
  color: rgba(255, 255, 255, 0.65);
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
}

/*
ion-content {
  --background: #000000;
}
*/

.grid-create-wallet {
  padding-left: 110px;
}

.col-wallet {
  display: flex;
  justify-content: flex-end;
}

.col-info {
  display: flex;
  justify-content: left;
  padding-left: 60px;
}

.button-wallet {
  --background: #1a233f;
  --color: #3661F8;
  --border-radius: 15px;
  --border-color: rgba(54, 97, 248, 0.8);
  --border-style: solid;
  --border-width: 1px;

  font-size: 32px;
  font-weight: 900;
  height: 76px;
  letter-spacing: 4.16px;
  line-height: 51.2px;

}

.button-info {
  --background: #1a233f;
  --color: #0098EE;
  --border-radius: 15px;
  --border-color: rgba(54, 97, 248, 0.8);
  --border-style: solid;
  --border-width: 1px;

  opacity: 0.6;
  font-weight: 700;
  font-size: 20px;
  height: 76px;
  width: 250px;
  text-transform: none;
}

.card-home {
  --background: rgba(123, 123, 123, 0.09);
  --color: #ffffff;
  border-radius: 10px;
  border: 1px solid #000000;
  height: 150px;
  width: 200px;
}

.card-title-home {
  --color: #ffffff;
  font-family: var(--font-family-inter);
  line-height: 150%;
  font-size: 16px;
}


.header-handler {
  color: #A3A3A3;
}

.card-img-twitter {
  height: 40px;
}

.home-block-column{
  display: flex;
  justify-content: center;
}

.grid-home{
  width: 80%;
  padding-top: 50px;
}

.home-block-card {
  padding-top: 10px !important;
}