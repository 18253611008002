.title-header {
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding: 1rem;
    padding-right: 4rem;
    font-size: 13px;
    font-family: var(--font-family-inter);
    color: #FFFFFF;
  }

