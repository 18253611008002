.roundedInput {
    --border-radius: 20px;
    --border-width: 1px;
    --box-shadow: 2px gray;
    --highlight-height: 0;
    --background: #f8f9fa;
    width: 250px;
    
}


ion-input.custom-input  {
    --background: rgb(255, 255, 255);
    --color: rgb(0, 0, 0);
    --border-radius: 25px;
}

.custom-input{
    --side-min-width: 220px;
}

