

.input-address {
  background: #1c1d1b;
  border-radius: 10px;
  color: #ffffff;
  z-index: 0;
}



.text-send-invite {
  color: #3661f8;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
}

.container-bottom {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.button-send-cancel {
  --background: #1F201D;
  --color: #ffffff;
  --border-radius: 10px;
  font-size: 22px;
  line-height: 42px;
  text-transform: capitalize;
}

.button-send-continue {
  --background: #1a233f;
  --color: #ffffff;
  --border-radius: 10px;
  font-size: 22px;
  line-height: 42px;
  text-transform: capitalize;
}


.activity-col {
  text-align: left;
}

ion-modal {
  --background: #131411;
  --ion-background-color: transparent;
  --width: 25%;
}