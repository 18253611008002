
ion-thumbnail {
    --size: 60px
}


h1 {
    color: white;
  }

  
ion-modal {
    --ion-background-color: #ffffff85;
  }