
.text-h1 {
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 150%;
    text-align:center;
  }

  .container-card-expert {
    --background: rgba(123, 123, 123, 0.09);
    --color: #ffffff;
    border-radius: 10px;
    border: 1px solid #000000;
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: auto;
  }

  .title-wallet-expert {
    display: flex;
  }

.button-generate-step-1 {
    --background: #06A1C3;
  --color: #FFFFFF;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 1px;
  width: 230px;

  font-family: var(--font-family-inter);
  font-size: 14px;  
}

.segment-stepper {
  width: 200px;
  margin: auto;
}

.stepper-label-1 {
  padding-left: 35px;
  /*min-width: 50px;*/
}

.stepper-label-2 {
 /* min-width: 50px; */
}

.stepper-label-3 {
  padding-right: 35px !important;
  /* min-width: 50px; */
}

.stepper-create-wallet.active {
  background-color: #3661F8 !important;
}
.stepper-create-wallet.completed {
  background-color: #3661F8 !important;
}
.stepper-create-wallet {
  background-color: #444543 !important;
}

.container-stepper{
  width: 400px;
  margin: auto;
}
