.container-content-page {
  margin: 10px 0 0 0;
}

.container-trending {
  display: flex;
  background-color: #131411;
  gap: 5px;
  height: 30px;
  margin-left: 15px;
  padding-left: 15px;
  width: 97.6%;
}

.container-trending-round-up {
  border-radius: 10px 10px 0 0;
}

.label-trending {
  color: #8d8e8d;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 700;
  padding-left: 15px;
}

.label-pair {
  color: #3660f7db;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 900;
  letter-spacing: 0.2px;
  line-height: 150%;
  padding-left: 25px;
  width: 8%;
}

.icon-trending {
  padding-left: 4px;
  width: 12px;
  padding-bottom: 3px;
}

.grid-tokens-header {
  margin: 0 15px 0 15px;
  font-family: var(--font-family-inter);
  padding: 10px 0 0 0;
}

.grid-tokens {
  margin: 0 15px 0 15px;
  font-family: var(--font-family-inter);
  padding: 0;
  /*
    background-color: rgba(123, 123, 123, 0.09);
    border: solid 1px #fff;
    */
}

.row-token-header {
  background-color: #1c1d1b;
  /*border: solid 3px #131411; */
  color: #ffffff;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 0.17em;
}

.row-token {
  background-color: #1c1d1b;
  /*border: solid 3px #131411; */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.17em;
  color: rgba(255, 255, 255, 0.9);
  height: 48px;
}

.flex-col-token {
  display: flex;
  gap: 20px;
  padding-left: 5px;
  padding-top: 4px;
  /* color: rgba(255, 255, 255, 0.9); */
}

.col-token {
  min-width: fit-content;
}

.col-balance-value {
  margin: auto;
}

.thumbToken {
  --size: 30px;
}

.token-symbol {
  padding-top: 5px;
}

.searchbar {
  /* color={"#1C1D1B"} */
  max-width: 230px;
  --box-shadow: 0;
  --background: #262626;
  --color: #fff;
  --placeholder-color: #fff;
  --icon-color: #fff;
  --clear-button-color: #fff;

  --border-radius: 12px;
  padding-bottom: 0px;
}

.alert-wrapper.sc-ion-alert-md {
  background: #1c1d1b;
}
.alert-radio-label.sc-ion-alert-md {
  color: #ffffff;
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: #ffffff;
}

.button-filter {
  --background: #1c1d1b;
  --box-shadow: 0;
}

.col-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.col-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.col-border {
  /* border: solid 4px #131411; */
  
  align-items: flex-end;
}

ion-ripple-effect {
  --background-color: aqua !important;
  color: aqua !important;
}

.img-size {
  width: 100%;
}

.table-token-head {
  font-family: var(--font-family-inter);
  color: rgba(123, 123, 123, 1);
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
}

.table-token-col {
  padding-left: 2rem;
}

.button-send-receive {
  --background: #262626;
  --color: #989898;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 1px;
  box-shadow: 0px 4px 4px #111110;

  font-family: var(--font-family-inter);
  font-size: 12px;
  text-transform: none;
  width: 5rem;
}

.has-details {
  position: relative;
}

.has-details:hover span {
  transform: translateY(70%) scale(1);
  background-color: #2e2f2e;
  padding-left: 40px;
}
.details {
  position: absolute;
  top: -38px;
  transform: translateY(70%) scale(0);
  transition: transform 0.1s ease-in;
  transform-origin: left;
  display: flex;
  background: #2e2f2e;
  z-index: 20;
  /* min-width: 100%;
  padding: 1rem;*/
}

.token-row:hover {
  background-color: #2e2f2e;
}

.row-tokens-rounded {
  border-radius: 0px 0px 10px 10px;
}

/*
ion-select {
    --placeholder-color: #971e49;
    --placeholder-opacity: 1;
    width: 100%;
    justify-content: center;
  }
  
  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }
  
  ion-select::part(placeholder)::first-letter {
    font-size: 24px;
    font-weight: 500;
  }
  
  ion-select::part(text) {
    color: #545ca7;
  }
  
  ion-select::part(icon) {
    color: #971e49;
    opacity: 1;
  }
  */
