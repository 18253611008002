.select-token-col {
  /*
  display: flex;
  flex-direction: row;
  justify-content: center;
*/
}

.container-select {
  /* flex: 1;*/
  color: #ffffff;
  /*background-color: #1c1d1b;*/
  z-index: 1;
  display: flex;
}

.select-label-text {
  padding: 8px 0 0 10px;
}

.icon-send-invite {
  padding-left: 10px;
}

.list-follower {
  cursor: pointer;
}

.text-send-secondary {
  color: #aeaeae;
  font-size: 14px;
}

.list-follower {
  color: #ffffff;
}

.container-send-invite {
  font-size: 12px;
  background: #1c1d1b;
  border-radius: 10px;
  height: 40px;
  text-align: center;
  padding: 8px 0px 8px 0px;
  margin: 10px;
}

.modal-twitter-text {
  color: #a3a3a3;
  font-family: var(--font-family-inter);
  font-size: 12px;
  letter-spacing: 0.17em;
  background-color: #1c1d1b;
  width: 95%;
}

.text-twitter {
  padding-left: 10px;
}