
.button-store-seed {
    --background: rgba(0, 194, 237, 0.69);
    --color: #FFFFFF;
    --border-color: #000000;
    --border-style: solid;
    --border-width: 1px;
  
    font-family: var(--font-family-inter);
    font-size: 14px;
    text-transform: none;
  }