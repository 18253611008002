
ion-segment-button {
    color: #FFFFFF;
    --color-hover:#424242;
}


.segment-button-checked{
    color: var(--color-checked);
}
