.button-store-seed2 {
    --background: rgba(0, 194, 237, 0.69);
    --color: #FFFFFF;
    --border-color: #000000;
    --border-style: solid;
    --border-width: 1px;
  
    font-family: var(--font-family-inter);
    font-size: 14px;
    text-transform: none;
    width: 18rem;
}

.button-copy {
  --background: rgba(123, 123, 123, 1);
  --color: #FFFFFF;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 1px;

  font-family: var(--font-family-inter);
  font-size: 12px;
  text-transform: none;
  width: 10rem;
}

.col-content-card-seed {
    /* border: solid 1px #fff;
    text-align: left; */
}

.grid-card-seed {
    width: 38rem;
    font-family: var(--font-family-inter);
}

.numero{
 color: rgba(123, 123, 123, 1);
 font-size: smaller;
}

.rectangle-flex {
  display: flex;
  align-items: center;
}

.rectangle {
    background-color: rgba(42, 42, 42, 1);
    border: 0.5px solid;
    border-color: rgba(123, 123, 123, 1);
    border-radius: 5px;
    height: 30px;
    width: 120px;
  }

  .number {
    height: 30px;
    left: 10px;
    letter-spacing: 0.2px;
    line-height: 11.6px;
    text-align: center;
    width: 22px;
  }
  
  .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .line {
    height: 30px;
    width: 1px;
  }

  .labs {
    height: 46px;
    letter-spacing: 0.2px;
    line-height: 14.5px;
    width: 75px;
  }

  
  .rectangle-seed {
    width: 81px; 
    height: 30px;
    border-radius: 5px; 
    background: #2b2b2b;
    border-width: 0.5px; 
    border-color: #7b7b7b;
  }