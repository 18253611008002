.disconnect-button {
    cursor: pointer;
}

.img-profile {
    overflow: hidden;
    border-radius: 50%;
}
  
.icon-eye {
    cursor: pointer;
}