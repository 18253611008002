.container-logo {
  display: flex;
}

.img-logo {
  height: 40px;
}

.text-logo {
  font-family: var(--font-family-prompt);
  font-size: 22px;
  letter-spacing: 2.5px;
  /* color: var(--royal-blue); */
}

.logo0 {
  padding-left: 7px;
  color: var(--royal-blue);
  padding-top: 5px;
}

.logo1 {
  color: #fff;
  padding-top: 5px;
}

.title {
  color: transparent;
  font-family: var(--font-family-prompt);
  font-style: normal;
  font-weight: 900;
  font-size: var(--font-size-xxl3);
  letter-spacing: 3.2px;
  margin-top: 2px;
}

ion-menu {
  width: auto;
}

ion-item {
  --color: #ffffff;
}

ion-list-header {
  --color: #ffffff;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
}

/*
ion-content::part(scroll) {
overflow: hidden;
}
*/

ion-menu.md ion-list {
  /*  
  padding: 20px 0;
  */
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  /* border-bottom: 1px solid var(--ion-color-step-150, #d7d8da); */
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-menu::part(backdrop) {
  background-color: rgba(255, 255, 255, 0.65);
}

ion-menu::part(container) {
  /* border-radius: 0 20px 20px 0; */
  /* box-shadow: 4px 0px 16px var(--royal-blue); */
}

.footer-menu {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  gap: 1.25rem;
  padding-bottom: 1rem;
}

.social-link {
  display: flex;
  padding-right: 10px;
  margin: 8px;
  width: 170px;
}

.social-link:hover {
  background-color: rgba(var(--ion-color-primary-rgb), 0.14);
}

.social-link-a {
  color: #ffffff;
  text-decoration: none;
}

.icon-social {
  width: 20px;
}

.social-link-text {
  padding-left: 10px;
  font-size: 16px;
}

.socials-menu {
  color: var(--ion-color-primary);
}

.socials-menu-hover:hover {
  cursor: pointer;
  background-color: rgba(var(--ion-color-primary-rgb), 0.14);
}

.socials-menu-hover {
  display: block;
}

.icon-caret {
  height: 10px;
  padding-left: 10px;
  padding-top: 5px;
}

.container-social-menu {
  display: flex;
  font-size: 16px;
}

.menu-padding {
  padding-bottom: 26px;
}

.menu-docs {
  font-size: 16px;
}

.menu-text {
  font-size: 18px !important;
}

.social-text-color {
  color: #3660f7 !important;
  font-weight: 500;
  font-family: var(--ion-font-family, inherit);
}

.disconnect-button {
  cursor: pointer;
}