.seed-scroll {
  overflow-y: scroll;
  max-height: 200px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(54, 96, 248, 1) !important;
}

.grid-card-step3 {
  width: 38rem;
  font-family: var(--font-family-inter);
}

.verify-seed-list {
  background-color: #252623;
}

.verify-seed-item {
  --background: #252623;
}

.input-seed {
  --background-color: rgba(217, 217, 217, 1);
}

.rectangle-input {
  background-color: #131411;
  border: 0.5px solid;
  border-color: rgba(123, 123, 123, 1);
  border-radius: 5px;
  height: 30px;
  width: 120px;
}

.icon-back {
  cursor: pointer;
}

.check-mark {
  display: contents;
}

.check-mark-icon {
  width: auto;
}
