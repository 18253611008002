.container-button {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.button-connect {
    --background: #1a233f;
    --color: #3661f8cc;
    --border-radius: 15px;
    --border-color: rgba(54, 97, 248, 0.8);
    --border-style: solid;
    --border-width: 1px;
  
    font-size: 16px;
    font-weight: 900;
    /* height: 26px; */
    letter-spacing: 0.13em;
    line-height: 160%;
    text-transform: none;
}

.button-sign-in {
    color: #3660f7;
}