.input-address {
  background: #1c1d1b;
  border-radius: 10px;
  color: #ffffff;
  height: 30px;
  z-index: 0;
}

.container-grid {
  font-size: 12px;
}

.container-send {
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
}

.button-send-dm {
  --background: #1f201d;
  --color: #aeaeae;
  --border-radius: 10px;
  font-size: 14px;
  line-height: 42px;
  text-transform: capitalize;
}

.text-coming-soon { 
  font-size: 12px;
}

.button-send-social {
  --background: #2D48A8;
  --color: #ffffff;
  --border-radius: 10px;
  font-size: 14px;
  line-height: 42px;
  text-transform: capitalize;
}

.container-send-amount {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.container-send-amount-max {
  display: flex;
  justify-content: space-between;
} 

.padding-send {
  padding: 9px;
}

.send-amount {
  font-size: 12px;
  background: #1c1d1b;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  width: 95%;
  padding: 8px 8px 8px 8px;
}

.input-amount {
  width: 80px;
  color: #ffffff;
}

.input-padding {
  --padding-top: 2px;
}

.container-send-network {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  background: #1c1d1b;
  border-radius: 10px;
  height: 35px;
  width: 95%;
  text-align: center;
  margin: auto;
  padding: 8px 8px 8px 8px;
}

.send-size {
  font-size: 14px;
}

.max-text {
  padding-right: 10px;
}

.text-amount {
  padding-left: 10px;
}