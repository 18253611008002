
.text-h1 {
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 150%;
    text-align:center;
  }

  .container-card {
    --background: rgba(123, 123, 123, 0.09);
    --color: #ffffff;
    border-radius: 10px;
    border: 1px solid #000000;
    /* width: 580px; */
  }

  .col-card {
    display: flex;
    justify-content: center;
  }

  .h1-card {
    font-family: var(--font-family-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    margin: auto;
  }

.col-content-card {
    text-align: center;
}

.grid-card {
    width: 16.75rem;
    font-family: var(--font-family-inter);
}

.card-create-wallet {
  --background: rgba(123, 123, 123, 0.09);
  --color: #ffffff;
  border-radius: 10px;
  border: 1px solid #000000;
}



.button-continue-twitter {
  --background: #06A1C3;
  --color: #FFFFFF;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 1px;

  font-family: var(--font-family-inter);
  font-size: 14px;
}

.button-create {
  --background: #06A1C3;
  --color: #FFFFFF;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 1px;
  width: 230px;

  font-family: var(--font-family-inter);
  font-size: 14px;
}

.header-handler {
  color: #A3A3A3;
}

.container-stepper {
  display: none;
}